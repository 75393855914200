import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OutsourcingService {

  token : string;

  constructor(private http : HttpClient) {
    this.getToken()
    .subscribe((data) => {
      console.log(data);
      this.token = data.access_token;
    })
  }

  // USANDO O LAMBDA DE ACESSO
  // getToken() : Observable<any> {
  //   return this.http.get('https://t1u3g1tbhk.execute-api.eu-west-1.amazonaws.com/devstage');
  // }

  // USANDO DIRETAMENTE O ENDPOINT QUE O CLAYTON CRIOU
   getToken() : Observable<any> {
    debugger
    return this.http.post('https://api.integration.devtest.aws.scania.com/token/v1','grant_type=client_credentials&client_secret=MztfJXAPrDBniKcxbZJUd0OHOKwa&client_id=OwGXkXFONZwFb1fn0R4y2ZGjTzAa',{
      headers: new HttpHeaders({
        'Content-Type':'application/x-www-form-urlencoded'
      })
    })
  }
/*
  getToken() : Observable<any> {
    debugger
    return this.http.post('https://api.integration.devtest.aws.scania.com/token/v1','grant_type=client_credentials&client_secret=vL16OvX0GdS4bHKkr7vtDoegl1Ya&client_id=QKEOtjFzb4ArltkubqW0sp_UTjUa',{
      headers: new HttpHeaders({
        'Content-Type':'application/x-www-form-urlencoded'
      })
    })
  }*/

   postJira(apontamentosXML : any) : Observable<any> {
     return this.http.post('https://api.integration.devtest.aws.scania.com/entrytime/v1?op=AddWorklog',apontamentosXML,{
       headers: {
         'Content-Type':'text/xml',
         'Authorization':'Bearer ' + this.token,
       },
       responseType: 'text'
     });
   }

  //postJira(teste : any) : Observable<any> {
  //  return this.http.post('http://localhost:58495/Jira.asmx/AddWorklog',teste,{responseType: 'text'});
  //}

}
